.cardsList {
    padding: 2rem;
    margin: 2rem;
}

.maxWidth {
    width: 100%;
}

.createNewNoteCard {
    height: 100%;
}

i.icon.trash:hover {
    color: red;
}

i.icon.check:hover {
    color: green;
}

i.icon.paint.brush:hover {
    color: rgb(0, 0, 0);
}

i.icon.tint:hover {
  color: rgb(0, 0, 0);
}

i.icon.text.height:hover {
  color: rgb(0, 0, 0);
}

.noteHeading {
  width: 100%;
}

.noteText {
  width: 100%;
}

div.ui.container {
  margin-left: 0!important;
  margin-right: 0!important;
  width: 100%;
}

.createNewListItem {
  height: 5%;
  max-height: 10%;
}

i.icon.delete:hover {
  color: rgb(207, 38, 38);
}

.cardDepth {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)!important;
}

.cardDepth:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)!important;
}

.noteText {
  font-family: sans-serif;
  line-height: 1.2857;
  margin-bottom: 0px!important;
  word-wrap: break-word;
  word-brake: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

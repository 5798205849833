#infoContainer {
  height: 100vh;
}

label.inputLabel {
  width: 65px !important;
  text-align: left !important;
}

#passwordResetButton:hover {
  background-color: #db2828 !important;
  color: white !important;
}

#passwordResetButton:active {
  background-color: #8d1a1a !important;
  color: white !important;
}

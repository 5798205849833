body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i.icon.arrow.left:hover {
  color: rgb(179, 17, 17);
}

#authContainer {
  height: 100vh;
}

.cardsList {
    padding: 2rem;
    margin: 2rem;
}

.maxWidth {
    width: 100%;
}

.createNewNoteCard {
    height: 100%;
}

i.icon.trash:hover {
    color: red;
}

i.icon.check:hover {
    color: green;
}

i.icon.paint.brush:hover {
    color: rgb(0, 0, 0);
}

i.icon.tint:hover {
  color: rgb(0, 0, 0);
}

i.icon.text.height:hover {
  color: rgb(0, 0, 0);
}

.noteHeading {
  width: 100%;
}

.noteText {
  width: 100%;
}

div.ui.container {
  margin-left: 0!important;
  margin-right: 0!important;
  width: 100%;
}

.createNewListItem {
  height: 5%;
  max-height: 10%;
}

i.icon.delete:hover {
  color: rgb(207, 38, 38);
}

.cardDepth {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)!important;
}

.cardDepth:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)!important;
}

.noteText {
  font-family: sans-serif;
  line-height: 1.2857;
  margin-bottom: 0px!important;
  word-wrap: break-word;
  word-brake: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.menu .item:hover {
  background-color: #3f3e3e!important;
  cursor: pointer;
}

.ui.menu .header.item:hover {
  background-color: #1b1c1d!important;
  cursor: auto;
  cursor: initial;
}

#infoContainer {
  height: 100vh;
}

label.inputLabel {
  width: 65px !important;
  text-align: left !important;
}

#passwordResetButton:hover {
  background-color: #db2828 !important;
  color: white !important;
}

#passwordResetButton:active {
  background-color: #8d1a1a !important;
  color: white !important;
}

#infoContainer {
  height: 100vh;
}

label.inputLabel {
  width: 65px !important;
  text-align: left !important;
}

#passwordResetButton:hover {
  background-color: #db2828 !important;
  color: white !important;
}

#passwordResetButton:active {
  background-color: #8d1a1a !important;
  color: white !important;
}

